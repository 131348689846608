import $ from 'jquery'
import Handlebars from 'handlebars'
import slider from './modules/slider'


/* Navigation */
$('#menuTrig').on('click', function () {
    $(this).toggleClass('js-open')
    $('#navMain').slideToggle().toggleClass('js-open')
})

/* SmoothScroll */
$('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000, function () {
                    // Callback after animation
                    // Must change focus!
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) { // Checking if the target was focused
                        return false;
                    } else {
                        $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                        $target.focus(); // Set focus again
                    }
                    ;
                });
            }
        }
    });

/* Load menù from dato */
$(function () {
    $.ajax({
        method: "POST",
        url: "https://graphql.datocms.com/",
        contentType: "application/json",
        headers: {
            Authorization: "4bfc34f2a3685948c077e08e85e4d3"
        },
        data: JSON.stringify({
            query: `query MyQuery {
                          allSections(orderBy: order_ASC) {
                            label
                            menusLeft {
                              label
                              glassPrice
                              items {
                                ... on ItemRecord {
                                  type: __typename
                                  name
                                  price
                                  description
                                  addons {
                                    name
                                    price
                                  }
                                }
                                ... on WineRecord {
                                  type: __typename
                                  name
                                  glassPrice
                                  bottlePrice
                                  description
                                }
                              }
                            }
                            menusRight {
                              label
                              glassPrice
                              items {
                                ... on ItemRecord {
                                  type: __typename
                                  name
                                  description
                                  price
                                  addons {
                                    name
                                    price
                                  }
                                }
                                ... on WineRecord {
                                  type: __typename
                                  name
                                  glassPrice
                                  bottlePrice
                                  description
                                }
                              }
                            }
                          }
                        }`,
            variables: {
                "entry": $('#entry').val()
            }
        })
    }).done(function (data) {
        Handlebars.registerHelper('iswine', function (value) {
            return value !== "ItemRecord";
        });

        const template_html = document.getElementById('menu-template').innerHTML;
        const template = Handlebars.compile(template_html);
        document.getElementById('menu').innerHTML = template(data.data);
    });
});

/* Load info from dato */
$(function () {
  $.ajax({
      method: "POST",
      url: "https://graphql.datocms.com/",
      contentType: "application/json",
      headers: {
          Authorization: "4bfc34f2a3685948c077e08e85e4d3"
      },
      data: JSON.stringify({
          query: `query MyQuery {
                        info {
                          openingHours
                        }
                      }`,
          variables: {
              "entry": $('#entry').val()
          }
      })
  }).done(function (data) {
      console.log(data)
      const template_html = document.getElementById('opening-hours-template').innerHTML;
      const template = Handlebars.compile(template_html);
      document.getElementById('opening-hours').innerHTML = template(data.data);
      document.getElementById('opening-hours-header').innerHTML = template(data.data);
      const par = document.getElementById('opening-hours').getElementsByTagName('p');
      for(var i = 0; i < par.length; i++)
      {
          par[i].classList.add('md-text');
      }
      const parHead = document.getElementById('opening-hours-header').getElementsByTagName('p');
      for(var i = 0; i < parHead.length; i++)
      {
        parHead[i].classList.add('md-text');
      }
        });
});

/* Load images from dato */
$(function () {
  $.ajax({
      method: "POST",
      url: "https://graphql.datocms.com/",
      contentType: "application/json",
      headers: {
          Authorization: "4bfc34f2a3685948c077e08e85e4d3"
      },
      data: JSON.stringify({
          query: `query MyQuery {
            image {
              slider {
                responsiveImage(imgixParams: {auto: compress}) {
                  srcSet
                  src
                  sizes
                }
              }
              heritageVertical {
                responsiveImage(imgixParams: {auto: compress}) {
                  srcSet
                  src
                  sizes
                }
              }
              heritageHorizontal {
                responsiveImage(imgixParams: {auto: compress}) {
                  srcSet
                  src
                  sizes
                }
              }
              restaurantVerticalRight {
                responsiveImage(imgixParams: {auto: compress}) {
                  srcSet
                  src
                  sizes
                }
              }
              restaurantHorizontal {
                responsiveImage(imgixParams: {auto: compress}) {
                  srcSet
                  src
                  sizes
                }
              }
              restaurantVerticalLeft {
                responsiveImage(imgixParams: {auto: compress}) {
                  srcSet
                  src
                  sizes
                }
              }
            }
                      }`,
          variables: {
              "entry": $('#entry').val()
          }
      })
  }).done(function (data) {
      console.log(data.data)
      const template_html = document.getElementById('slider-template').innerHTML;
      const template = Handlebars.compile(template_html);
      document.getElementById('slider').innerHTML = template(data.data);
      /* Init carousel */
      const gallery = document.querySelector('#slider .tiny-slider')
    if (gallery) {
    gallery.slider = slider(gallery)
    }
    /** fine carousel */
    /** heritage vertical */
    const templateHV_html = document.getElementById('heritage-vertical-template').innerHTML;
      const templateHV = Handlebars.compile(templateHV_html);
      document.getElementById('heritage-vertical').innerHTML = templateHV(data.data);
      /** heritage horizontal */
    const templateHH_html = document.getElementById('heritage-horizontal-template').innerHTML;
    const templateHH = Handlebars.compile(templateHH_html);
    document.getElementById('heritage-horizontal').innerHTML = templateHH(data.data);
    /** restaurant  */
    const templateR_html = document.getElementById('restaurant-template').innerHTML;
    const templateR = Handlebars.compile(templateR_html);
    document.getElementById('restaurant-image').innerHTML = templateR(data.data);
    });
});



