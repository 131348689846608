
import {tns} from 'tiny-slider/src/tiny-slider'
import 'tiny-slider/src/tiny-slider.scss'

const slider = (el) => {
  return tns({
    container: el,
    loop: true,
    items: 1,
    slideBy: 'page',
    mode: 'gallery',
    autoplay: true,
    speed: 1200,
    controls: false,
    autoplayTimeout: 3000,
    autoplayButtonOutput: false,
    autoplayButton: false,
    navPosition: 'bottom'
  }); 
}

export default slider